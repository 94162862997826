<template>
    <b-row>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="ml-1 mb-2">
                        <b-row class="px-2 d-flex justify-content-between">
                          <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Mostrar</label>
                            <v-select
                                v-model="pageLength"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="searchLimitOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="changeLength"
                            />
                          </div>
                            <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                                <button class="btn btn-primary" @click="exportToExcel">Exportar a Excel</button>
                            </div>
                        </b-row>
                    </div>

                    <b-table
                        ref="refUserListTable"
                        class="position-relative table-size-list"
                        :items="children_installations"
                        responsive
                        sticky-header
                        striped
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="No hay registros"
                    >
                        <template #cell(segment)="data">
                            {{
                                data.item.master_installation.segment ? data.item.master_installation.segment.segment : null
                            }}
                        </template>

                        <template #cell(active)="data">
                            <b-badge :variant="data.item.active_installation === 1 ? 'success' : 'danger'">
                                {{ data.item.active_installation === 1 ? 'Si' : 'No' }}
                            </b-badge>
                        </template>

                        <template #cell(draft)="data">
                            <b-badge :variant="data.item.draft === 1 ? 'success' : 'danger'">
                                {{ data.item.draft === 1 ? 'Si' : 'No' }}
                            </b-badge>
                        </template>

                        <template #cell(responsible)="data">
                            {{
                                data.item.master_installation.responsible ? data.item.master_installation.responsible.name : null
                            }}
                        </template>

                        <template #cell(figure)="data">
                            {{
                                figures[data.item.master_installation.figure_id - 1] ? figures[data.item.master_installation.figure_id - 1].name : null
                            }}
                        </template>

                        <template #cell(country)="data">
                            {{
                                data.item.master_installation.province_country
                                    ? data.item.master_installation.province_country.country
                                    : null
                            }}
                        </template>

                        <template #cell(province)="data">
                            {{
                                data.item.master_installation.province_country
                                    ? data.item.master_installation.province_country.province
                                    : null
                            }}
                        </template>

                        <template #cell(installation_type)="data">
                            {{ data.item.installation_type ? data.item.installation_type.type : null }}
                        </template>

                        <template #cell(date_start)="data">
                            {{ data.item.start_date ? helpers.formatDate(data.item.start_date) : null }}
                        </template>

                        <template #cell(date_end)="data">
                            {{ data.item.end_date ? helpers.formatDate(data.item.end_date) : null }}
                        </template>

                        <template #cell(level_1_id)="data">
                            {{ data.item.master_installation.level_1 ? data.item.master_installation.level_1.name : null }}
                        </template>

                        <template #cell(level_2_id)="data">
                            {{
                                data.item.master_installation.level_2 ? data.item.master_installation.level_2.name : null
                            }}
                        </template>

                        <template #cell(level_3_id)="data">
                            {{
                                data.item.master_installation.level_3 ? data.item.master_installation.level_3.name : null
                            }}
                        </template>

                        <template #cell(level_4_id)="data">
                            {{
                                data.item.master_installation.level_4 ? data.item.master_installation.level_4.name : null
                            }}
                        </template>

                        <template #cell(opsva_territory)="data">
                            {{
                                data.item.master_installation.territory_opsva ? data.item.master_installation.territory_opsva.name : null
                            }}
                        </template>

                        <template #cell(client_type)="data">
                            {{
                                data.item.master_installation.client_type ? data.item.master_installation.client_type.type : null
                            }}
                        </template>

                        <template #cell(company)="data">
                            {{ data.item.master_installation.company ? data.item.master_installation.company.name : null }}
                        </template>

                    </b-table>

                  <div class="mx-2 mb-2">
                    <b-row>
                      <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                        <span class="text-muted">Mostrando {{ pageLength }} de {{ totalRecords }} Instalaciones Hijas</span>
                      </b-col>

                      <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                        <b-pagination
                            :value="1"
                            :total-rows="totalRecords"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="changePage"
                        >
                          <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BOverlay, BBadge, BButton, BPagination} from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import ExcelJS from "exceljs"
import childrenInstallationVhe from "@/store/vhe/childrenInstallation/childrenInstallation"
import vSelect from "vue-select"

export default {
    name: "ListView",
    components: {
      BPagination,
      vSelect,
        BRow,
        BCol,
        BTable,
        BCard,
        BOverlay,
        BBadge,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            columns: [
              {key: 'master_installation.client_cif', label: 'CIF', sortable: false},
              {key: 'vhe_code', label: 'CODIGO_GEI_1', sortable: false},
              {key: 'equipment_code', label: 'CODIGO EQUIPO', sortable: false},
              {key: 'active', label: 'InstalaciónActiva', sortable: false},
              {key: 'draft', label: 'InstalaciónBorrador', sortable: false},
              {key: 'master_installation.client', label: 'Referencia', sortable: false},
              {key: 'responsible', label: 'Responsable', sortable: false},
              {key: 'figure', label: 'Figura RD171', sortable: false},
              {key: 'country', label: 'Pais', sortable: false},
              {key: 'province', label: 'Provincia/Zona', sortable: false},
              {key: 'master_installation.city', label: 'Población', sortable: false},
              {key: 'installation_type', label: 'Tipología', sortable: false},
              {key: 'date_start', label: 'Fecha inicio explotación', sortable: false},
              {key: 'date_end', label: 'Fecha fin explotación', sortable: false},
              {key: 'period_description', label: 'Duración', sortable: false},
              {key: 'level_1_id', label: 'Nivel 1', sortable: false},
              {key: 'level_2_id', label: 'Nivel 2', sortable: false},
              {key: 'level_3_id', label: 'Nivel 3', sortable: false},
              {key: 'level_4_id', label: 'Nivel 4', sortable: false},
              {key: 'opsva_territory', label: 'Territorio_OPSVA', sortable: false},
              {key: 'client_type', label: 'Tipo cliente', sortable: false},
              {key: 'master_installation.client_contact_person', label: 'Contacto', sortable: false},
              {key: 'master_installation.client_phone', label: 'Teléfono', sortable: false},
              {key: 'master_installation.client_email', label: 'Email', sortable: false},
              {key: 'master_installation.business_model', label: 'Modelo de Negocio', sortable: false},
              {key: 'master_installation.public_infra', label: 'Public Infra', sortable: false},
              {key: 'company', label: 'Empresa', sortable: false},
              {key: 'serial_number', label: 'Numero de serie', sortable: false},
              {key: 'master_installation.address', label: 'Dirección Postal', sortable: false},
              {key: 'master_installation.postal_code', label: 'Código Postal', sortable: false},
            ],
            showLoading: false,
            helpers,
            searchLimitOptions: ['3','5','10','20','50','100','Todas'],
            figures: [
                {'id': 1, 'name': 'MANTENEDOR'},
                {'id': 2, 'name': 'PROPIETARIO'},
                {'id': 3, 'name': 'TITULAR'}
            ],
            allChildrenInstallations: null,
        }
    },
    computed: {
        ...mapState('childrenInstallationVhe', ['children_installations']),
    },
    created() {
        this.getInstallations()
    },
    methods: {
      can(permission) {
        return this.$store.getters.can(permission)
      },
      formatDate(date) {
        const dateFormated = new Date(date)

        return dateFormated.toLocaleDateString("es-ES")
      },
      getInstallations() {
        this.showLoading = true

        this.$store.dispatch('childrenInstallationVhe/listAllChildrenInstallations', {
          relations: ['masterInstallation', 'masterInstallation.company', 'masterInstallation.responsible',
            'installationType', 'masterInstallation.territoryOpsva', 'masterInstallation.provinceCountry',
            'masterInstallation.clientType', "masterInstallation.level_1", "masterInstallation.level_2",
            "masterInstallation.level_3", "masterInstallation.level_4"],
          per_page: this.pageLength,
          page: this.page,
        })
            .then(response => {
              this.showLoading = false
              this.totalRecords = response.total_records
            })
            .catch(error => {
              this.showLoading = false
              console.log(error)
            })
      },
        changePage(page) {
            this.page = page
            this.getInstallations()
        },
      async exportToExcel() {
        try {
          this.showLoading = true

          const response = await this.$store.dispatch('childrenInstallationVhe/exportChildrenInstallations', {
            relations: ['masterInstallation', 'masterInstallation.company', 'masterInstallation.responsible',
              'installationType', 'masterInstallation.territoryOpsva', 'masterInstallation.provinceCountry',
              'masterInstallation.clientType', "masterInstallation.level_1", "masterInstallation.level_2",
              "masterInstallation.level_3", "masterInstallation.level_4"],
          })

          if (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'children_installation_list.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()

            this.showLoading = false
          }

        } catch (error) {
          console.error('Error al exportar el archivo:', error)
        }


      }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>